<style src="./assets/style/style.scss" lang="scss"></style>

<template>
  <div id="show-blogs">
    <input type="text" class="customSearch" v-model="search" placeholder="hledáš knihu?">
    <div class="booksWrapper">
      <div class="bookWrapper" v-for="book in filteredBooks" :key="book.name">
        <p v-if="book.title">{{book.title}}</p>
        <span v-if="book.title && book.authorsArray">,{{'\xa0'}}</span>
        <div v-if="book.authorsArray" class="bookAuthors">
          <p v-for="author in book.authorsArray">{{author}},{{'\xa0'}}</p>
        </div>
        <div v-if="book.publishersArray" class="bookPublishers">
          <p v-for="publisher in book.publishersArray">{{publisher}},{{'\xa0'}}</p>
        </div>
        <p v-if="book.place">{{book.place}}</p>
        <span v-if="book.place && book.year">,{{'\xa0'}}</span>
        <p v-if="book.year">{{book.year}}</p>
        <span v-if="book.year && book.isbnsArray">,{{'\xa0'}}</span>
        <div v-if="book.isbnsArray" class="bookPublishers">
          <p v-for="isbn in book.isbnsArray">{{isbn}},{{'\xa0'}}</p>
        </div>
        <p v-if="book.amount">{{book.amount}}</p><br>
      </div>
    </div>
    <a class="mailWrapper" href="mailto:dobrodruzstvo@protonmail.com" target="_blank">dobrodruzstvo@protonmail.com</a>
  </div>
</template>

<script>
import axios from 'axios'
import shuffle from 'lodash/shuffle'

const gsheet_url = "https://sheets.googleapis.com/v4/spreadsheets/1Oz6EcvY2Lo-FDJ7Q7DeMW6bpgo27EnvtSSTGhrkLWLM/values/harok1?alt=json&key=AIzaSyDosyOT245HYkPJRN960HVvSTCWUYhsY_w"

export default {
  data() {
    return {
      books : [],
      search : ""
    }
  },
  methods: {
    loadFilms () {
      const splitEntries = (entry, value) => {
        if (entry[value]) {
          const entriesArray = entry[value].split(", ")
          entry[`${value}Array`] = []
          entriesArray.forEach(isbn => {
            entry[`${value}Array`].push(isbn)
          })
        }
      }
      axios
        .get(gsheet_url)
        .then(response => response.data.values)
        .then(entries => {
          const parsedEntries = []
          entries.forEach(function(value, i) {
            if (value[0] && i !== 0) {
              let entry = {
                "title": value[0],
                "authors": value[1],
                "publishers": value[2],
                "place": value[3],
                "year": value[4],
                "isbns": value[5],
                "amount": value[6],
              }
              splitEntries(entry, 'authors')
              splitEntries(entry, 'publishers')
              splitEntries(entry, 'isbns')
              parsedEntries.push(entry)
            }
          })
          this.books = shuffle(parsedEntries)
          // _.sortBy(users,(o) => o.user.toLowerCase());
        })
    }
  },
  created() {
    this.loadFilms()
  },
  computed: {
    filteredBooks: function(){
      return this.books.filter((book) => {
        //return book.title.match(this.search)
        return book.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      })
    }
  }
}
</script>
